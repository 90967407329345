import React from 'react';
import { styled } from '@mui/system';
import { StyledPropsFix } from 'types';
import { Grid, Typography, Box, TextField, Button } from '@mui/material';

export const MenuList = styled(Grid)`
  font-family: Roboto Regular;
  list-style: none;
  line-height: 34px;
  ${({ theme }) => `

  color: ${theme.palette.text.secondary};
  `}
` as StyledPropsFix;
export const Logo = styled(Box)`
  filter: invert(1);
` as StyledPropsFix;

export const SocialIcon = styled(Box)`` as StyledPropsFix;

export const EmailInput = styled(TextField)({
  '& .MuiFilledInput-input': {
    backgroundColor: '#f9f9f9',
    borderRadius: '30px',
    fontSize: '1.125em',
    textTransform: 'none',
    fontFamily: 'Roboto Regular',
    paddingLeft: '1.4em',
    paddingRight: '3.0em',
    height: '1.325em',
  },
  '& .MuiFilledInput-root': {
    backgroundColor: 'rgb(0 0 0 / 0%)',
  },
  '& .MuiFilledInput-root:before': {
    transform: 'scaleX(0.0)',
  },
  '& .MuiFilledInput-root.Mui-focused:after': {
    transform: 'scaleX(0.82)',
    borderBottom: '2px solid #3F826D',
  },
  '& .MuiFilledInput-root:hover, .MuiFilledInput-root.Mui-focused': {
    backgroundColor: 'rgb(0 0 0 / 0%)',
  },
  '& .MuiInputLabel-filled': {
    color: '#acacac',
    transform: 'translate(12px, 19px) scale(1)',
    marginLeft: '0.8em',
  },
  '& .MuiInputLabel-shrink': {
    color: '#3F826D',
    transform: 'translate(12px, 7px) scale(0.75)',
  },
});

export const ActionButton = styled(Button)`
  ${({ theme }) => `
    text-transform: none;
    font-family: Roboto Regular;
    color: ${theme.palette.text.secondary};
    white-space: nowrap;
    &:hover {
      background-color: #5cc4e4;
    }

  ${theme.breakpoints.up('sm')} {
    margin-left: -2.6em;
    font-size: 1.125em;
  } 
  ${theme.breakpoints.down('md')} {
    margin-right: 0;
    font-size: 1.125em;
  }  
  ${theme.breakpoints.only('xs')} {
    margin-left: 0;
  }
  `}
` as StyledPropsFix;

export const NewsletterBox = styled(Box)`
  ${({ theme }) => `

  ${theme.breakpoints.up('md')} {
    white-space: nowrap;
  }  
  ${theme.breakpoints.only('xs')} {
    white-space: normal;
  }
  `}
` as StyledPropsFix;

export const EmailBox = styled(Box)`
  ${({ theme }) => `

  ${theme.breakpoints.only('xs')} {
    text-align: right;
  }
  ${theme.breakpoints.only('sm')} {
    white-space: nowrap;
  }
  `}
` as StyledPropsFix;

export const SocialBox = styled(Box)`
  ${() => `
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  `}
` as StyledPropsFix;

export const FooterContainer = styled(Grid)`
  margin: 0 auto;
  justify-content: space-between;
` as StyledPropsFix;

export const MiddleSection = styled(Grid)`
  ${({ theme }) => `

  display: flex;
  margin-top: 5.2em;
  justify-content: space-between;
  margin-bottom: 2em;

  ${theme.breakpoints.only('lg')} {
    flex-direction: row;
  }    
  ${theme.breakpoints.down('lg')} {
    flex-direction: column;
    gap: 5em;
  }  

  `}
` as StyledPropsFix;

export const BottomSection = styled(Grid)`` as StyledPropsFix;
export const TopSection = styled(Box)`
  ${({ theme }) => `
display: flex;
width: 100%;
white-space: nowrap;

${theme.breakpoints.only('lg')} {
  flex-direction: row;
}    
${theme.breakpoints.down('sm')} {
  flex-direction: column;
}  

`}
` as StyledPropsFix;

export const PrivacyPolicyText = styled(Typography)`
  ${({ theme }) => `

${theme.breakpoints.up('sm')} {
  display: block;
  float: unset;
  text-align: initial;
  padding-top: 1.3em;
  font-size: 1em;
}

${theme.breakpoints.only('xs')} {
  display: inline-grid;
  float: left;
  text-align: left;
  padding-top: 0.9em;
  font-size: 0.7em;
}

`}
` as StyledPropsFix;
