import React, { FormEvent } from 'react';
import * as Styles from './Footer.styles';
import { Grid, Typography, Box } from '@mui/material';
import facebookIcon from 'images/socialIcons/Facebook - Negative.png';
import discordIcon from 'images/socialIcons/Discord - Negative.png';
// import instagramIcon from 'images/socialIcons/Instagram - Negative.png';
import linkedinIcon from 'images/socialIcons/LinkedIn - Negative.png';
import telegramIcon from 'images/socialIcons/Telegram - Negative.png';
import twitterIcon from 'images/socialIcons/Twitter - Negative.png';
import mediumIcon from 'images/socialIcons/Medium - Negative.png';
import siteLogo from 'images/logo-traced.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Link from './FooterLink';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
import { useSubmitNewsletter } from './useSubmitNewsletter';

const privacyPolicy_link = '/PrivacyPolicy_Coreestate_2022.pdf';
const disclaimer_link = '/Disclaimer_Coreestate_2023.pdf';
export const terms_link = '/Terms_Coreestate_2023.pdf';

const menuItems = [
  {
    id: 1,
    title: { text: 'Company', link: '/company/' },
    items: [
      { id: 1, text: 'About us', link: '/company/#about-us' },
      { id: 2, text: 'Team', link: '/company/#team' },
      { id: 3, text: 'Careers', link: '/careers/' },
      {
        id: 4,
        text: 'Affiliate Program',
        url: 'https://coreestate.targetcircle.com/signup?ref=coreestate',
      },
    ],
  },
  {
    id: 2,
    title: { text: 'Legal', link: '#' },
    items: [
      { id: 1, text: 'Terms & Conditions', link: terms_link },
      {
        id: 2,
        text: 'Privacy Policy',
        link: privacyPolicy_link,
      },
      {
        id: 3,
        text: 'Disclaimer',
        link: disclaimer_link,
      },
    ],
  },
  {
    id: 3,
    title: { text: 'Support', link: '/support/' },
    items: [
      { id: 1, text: 'Getting started', link: '/support/#getting-started' },
      { id: 2, text: 'Pricing and fees', link: '/support/#pricing-and-fees' },
      {
        id: 3,
        text: 'Investing and risks',
        link: '/support/#investing-and-risks',
      },
      { id: 4, text: 'Contact us', link: '/support/#contact-us' },
      { id: 5, text: 'FAQ', link: '/faq/' },
    ],
  },
  {
    id: 4,
    title: { text: 'Learn', link: '/learn/' },
    items: [
      { id: 1, text: 'Coreestate token', link: '/learn/#coreestate-token' },
      {
        id: 2,
        text: 'What is tokenized real estate',
        link: '/learn/#what-is-tokenized-real-estate',
      },
      {
        id: 3,
        text: 'What is STO',
        link: '/learn/#what-is-security-token-offering',
      },
      {
        id: 4,
        text: 'Future of Coreestate tokens',
        link: '/learn/#future-of-coreestate-tokens',
      },
    ],
  },
];

const socialIconList = [
  // { id: 2, label: 'Instagram', src: instagramIcon, url: '' },
  {
    id: 1,
    label: 'Twitter',
    src: twitterIcon,
    url: 'https://twitter.com/coreestate_io',
  },
  {
    id: 2,
    label: 'LinkedIn',
    src: linkedinIcon,
    url: 'https://www.linkedin.com/company/coreestateglobal',
  },
  {
    id: 3,
    label: 'Discord',
    src: discordIcon,
    url: 'https://discord.com/invite/ntPWKCEYkz',
  },
  {
    id: 4,
    label: 'Telegram',
    src: telegramIcon,
    url: 'https://t.me/coreestate_io',
  },
  {
    id: 5,
    label: 'Facebook',
    src: facebookIcon,
    url: 'https://www.facebook.com/profile.php?id=100095371784390',
  },
  {
    id: 6,
    label: 'Medium',
    src: mediumIcon,
    url: 'https://medium.com/@coreestate',
  },
];

const Footer = (): JSX.Element => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  const { email, isFormValid, setEmail, handleSubmit } = useSubmitNewsletter();

  return (
    <Grid
      container
      columns={12}
      spacing={2}
      paddingY={4}
      paddingX={4}
      sx={{ backgroundColor: 'background.dark' }}
    >
      <Styles.FooterContainer container sx={{ maxWidth: `${sizeOfGrid}px` }}>
        <Styles.TopSection>
          {menuItems.map((columns) => (
            <Styles.MenuList
              item
              key={columns.id}
              sm={3}
              md={3}
              xs={12}
              sx={{ fontSize: { md: '1em', sm: '0.8em', xs: '0.8em' } }}
            >
              <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                <Link
                  href={columns.title.link}
                  color={theme.palette.text.secondary}
                >
                  <li
                    style={{
                      paddingBottom: '10px',
                      fontFamily: 'Roboto Bold',
                    }}
                  >
                    {columns.title.text}
                  </li>
                </Link>
                {columns.items.map((menuItem, i) => (
                  <Link
                    key={menuItem.id + i}
                    href={menuItem?.link || menuItem?.url || ''}
                    color={theme.palette.text.secondary}
                  >
                    <li style={{ fontSize: '1em' }}>{menuItem.text}</li>
                  </Link>
                ))}
              </ul>
            </Styles.MenuList>
          ))}
        </Styles.TopSection>
        <Styles.MiddleSection item xs={12}>
          <Styles.NewsletterBox>
            <Typography
              variant={'h5'}
              color="text.secondary"
              paddingBottom="36px"
            >
              To stay updated please subscribe to our newsletter.
            </Typography>

            <Styles.EmailBox>
              <Styles.EmailInput
                label="Enter your email"
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                variant="filled"
                sx={{
                  width: { sm: '22em', xs: '100%' },
                  paddingBottom: { xs: '1em' },
                }}
              />
              <Styles.ActionButton
                onClick={(e: FormEvent) =>
                  isFormValid ? handleSubmit(e) : null
                }
                variant="contained"
                color="primary"
              >
                Subscribe {!isXs && ' to newsletter'}
              </Styles.ActionButton>
              <Styles.PrivacyPolicyText variant={'subtitle2'} color="#909090">
                You can unsubscribe at any time.{' '}
                <Link href={privacyPolicy_link} target="_blank" color="#40826D">
                  Read our privacy policy.
                </Link>
              </Styles.PrivacyPolicyText>
            </Styles.EmailBox>
          </Styles.NewsletterBox>
          <Styles.SocialBox>
            <Typography
              variant={'h5'}
              color="text.secondary"
              paddingBottom="1.6em"
            >
              And follow us on social media.
            </Typography>
            <Box sx={{ display: 'flex', gap: { lg: 5, sm: 5, xs: 3 } }}>
              {socialIconList.map((socialIcon) => (
                <Box key={socialIcon.id} height="25px" width="25px">
                  <Link href={socialIcon.url} target="_blank">
                    <Styles.SocialIcon
                      src={socialIcon.src}
                      component={'img'}
                      height={1}
                      width={1}
                    />
                  </Link>
                </Box>
              ))}
            </Box>
          </Styles.SocialBox>
        </Styles.MiddleSection>
        <Styles.BottomSection item xs={12}>
          <Box maxHeight={'54px'} maxWidth={'222px'} marginY={6}>
            <Styles.Logo
              src={siteLogo}
              component={'img'}
              alt="Coreestate tokenized consumer rentals logo"
              height={1}
              width={1}
            />
          </Box>
          <Typography
            variant={'subtitle2'}
            color="text.secondary"
            sx={{
              fontWeight: 'normal',
              textAlign: 'left',
              color: '#909090',
            }}
          >
            &copy; Coreestate. All rights reserved. Coreestate Aps is a Danish
            company, Reg nr: 42809888, located at AI Innovation House,
            Innovation Alle 3, DK-7100 Vejle, Denmark.
          </Typography>
          <Typography
            variant={'subtitle2'}
            color="text.secondary"
            sx={{
              paddingTop: 5,
              fontWeight: 'normal',
              textAlign: 'left',
              color: '#909090',
            }}
          >
            The content of this webpage is not an investment advice and does not
            constitute any offer or solicitation to offer or recommendation of
            any investment product. Investment involves risk. Past performance
            is not indicative of future performance. Investors should refer to
            the offering documentation of the projects for detailed information
            (including risk factors) prior to investing. No representation or
            warranty (either express or implied) is provided in relation to the
            accuracy, exactness, completeness, appropriateness, actuality, or
            reliability of the information, Materials, or opinions published on
            the Website.
          </Typography>
        </Styles.BottomSection>
      </Styles.FooterContainer>
    </Grid>
  );
};

export default Footer;
