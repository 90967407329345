import React, { FormEvent } from 'react';

export const useSubmitNewsletter = () => {
  const [email, setEmail] = React.useState('');
  const [isFormValid, setIsFormValid] = React.useState(false);

  const handleValidation = () => {
    if (!email || !email.includes('@')) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  };

  React.useEffect(() => {
    handleValidation();
  }, [email]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    // Submit the form
    const form = document.createElement('form');
    form.action =
      'https://app.us18.list-manage.com/subscribe/post?u=1be7374b784dea52762800cd7&amp;id=cf03425f4a&amp;f_id=00e50de7f0';
    form.method = 'post';
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'EMAIL';
    input.value = email;
    form.appendChild(input);
    document.body.appendChild(form);
    form.submit();
  };

  return {
    email,
    isFormValid,
    setEmail,
    handleSubmit,
  };
};
