import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import { StyledPropsFix } from 'types';
import heroBackground from 'images/indexPage/hero_image_grev55.webp';
import Link from '../Footer/FooterLink';

export const Logo = styled(Box)<{ ishomeview: 'true' | 'false' }>`
  ${({ ishomeview }) => (ishomeview ? 'filter: invert(1)' : '')};
` as StyledPropsFix;

export const LinkStyled = styled(Link)<{ ishomeview: 'true' | 'false' }>`
  font-family: ${({ ishomeview }) =>
    ishomeview ? 'Roboto Bold' : 'Roboto Regular'};
  font-size: 0.9em;
  white-space: nowrap;
  padding: 16px;
  display: inline-block;

  // Link looks
  margin: 0px;
  text-transform: none;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  color: rgb(255, 255, 255);
  cursor: pointer;
`;

export const MainTitle = styled(Typography)`` as StyledPropsFix;

export const SectionTitle = styled(Box)`
  .sectionTitle-title {
    font-family: Poppins SemiBold;
    font-size: 3.2em;
    color: ${({ theme }) => theme.palette.text.primary};
  }
  margin: 2em auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down('md')} {
    .sectionTitle-title {
      font-size: 2.2em;
    }
    margin-left: 0.8em;
  }
`;

export const ActionItem = styled(Button)<{ ishomeview: 'true' | 'false' }>`
  :hover {
    border: ${({ theme, ishomeview }) =>
      ishomeview === 'true'
        ? `3px solid ${theme.palette.text.secondary}`
        : `3px solid ${theme.palette.text.dark}`};
    background-color: ${({ ishomeview }) =>
      ishomeview === 'true' ? 'rgb(255 255 255 / 14%)' : 'rgb(0 0 0 / 6%)'};
  }

  padding: ${({ ishomeview }) => (ishomeview ? '5px 20px' : '10px 20px')};
  border: ${({ theme, ishomeview }) =>
    ishomeview === 'true'
      ? `3px solid ${theme.palette.text.secondary}`
      : `3px solid ${theme.palette.text.dark}`};
  background-color: ${({ ishomeview }) => (ishomeview ? 'unset' : '#40826D')};
  font-family: ${({ ishomeview }) =>
    ishomeview ? 'Roboto Bold' : 'Roboto Regular'};
  font-size: 0.9em;
  text-transform: none;
  color: ${({ theme, ishomeview }) =>
    ishomeview === 'true'
      ? theme.palette.text.secondary
      : theme.palette.text.dark};
  white-space: nowrap;
  margin: 0;
` as StyledPropsFix;

export const menuContainerValues = {
  gradient: 'linear-gradient(256.43deg, #58ac91 6.89%, #40826d 103.38%)',
  gradient_farmville:
    'linear-gradient(86.32deg, #e5e4e2 11.03%, #f0f0f0 77.7%)',
  transparent: 'transparent',
  white: '#fff',
  heroBackgroundImage: heroBackground,
  expandedMenuColor: '#737796',
};

export const { white, transparent, heroBackgroundImage, expandedMenuColor } =
  menuContainerValues;
export const MenuContainer = styled(AppBar)<{
  ishomeview: 'true' | 'false';
  menuexpanded: 'true' | 'false';
}>`
  & {
    box-shadow: unset;
    background: ${({ menuexpanded, ishomeview }) =>
      menuexpanded === 'true' && ishomeview === 'true'
        ? expandedMenuColor
        : transparent};
    margin-top: -20px;
    transition: background 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    z-index: 2300;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ ishomeview }) =>
      ishomeview === 'true' ? transparent : white};
  }
`;
